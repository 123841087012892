<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <span
      class="font-weight-light subtitle-1"
    >
      <v-btn
        elevation="1"
        color="blue darken-1"
        dark
        @click="goBack"
      >
        <v-icon
          color="white"
        >
          mdi-keyboard-backspace
        </v-icon>
        Back
      </v-btn>
    </span>     
    <base-material-card
      icon="mdi-file-document-outline"
      color="secondary"
      title="Mailer Files"
      style="background-color: #FAFAFA"
    >
 
      <v-row>
        <v-col></v-col>
      </v-row>
        <v-col>
          <v-card>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="items"
              :items-per-page="50"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template #item.created_at="{value}">
                {{ formatIsoDate(value, true) }}
              </template>
              <template v-slot:item.start_date="{ item }">
                {{ formatIsoDate(item.start_date, false) }} - {{ formatIsoDate(item.end_date, false) }}
              </template>
              <!-- <template #item.start_date="{value}">
                {{ formatIsoDate(value, true) }}
              </template>               -->
              <template #item.number_rows="{value}">
                {{ formatNumber(value, true) }}
              </template>              
              <template v-slot:item.filename="{ item }">
                <a
                  :href="item.report_url"
                  target="_blank"
                >
                  {{ item.filename }}
                </a>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">                
                    <v-icon
                      small
                      @click="deleteItem(item)"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete Report File</span>
                </v-tooltip>                        
              </template>              
            </v-data-table>
          </v-card>
        </v-col>
    </base-material-card>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noPermissions"
      persistent
      width="600"
    >
      <v-card
        color="warning"
        dark
      >
        <v-alert
          type="warning"
        >
          You do not have permissions to access this feature. Contact Dealer Insights support for further assistance.
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="goHome()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import { navigationMixin } from '@/mixins/navigationMixin.js'  
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import DmsService from '@/services/DmsService.js'  
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'

  export default {
    name: 'ScheduledReportFiles',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, titleMixin, navigationMixin],
    data () {
      return {
        sortBy: 'created_at',
        sortDesc: true,        
        loadingMessage: 'Loading',
        showErrorDialog: false,
        reportId: null,
        report: null,
        items: [],
        loading: true,
        error: '',
        expanded: [],
        noPermissions: false,
        headers: [
          { text: 'URL', value: 'filename'},
          { text: 'Number Rows', value: 'number_rows', align: 'center' },
          { text: 'Date Range', value: 'start_date', align: 'center' },
          { text: 'Created At', value: 'created_at', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },          
        ],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },  
      getTitle() {
        if (this.report != null) {
          console.log(this.report.title)
          return `${this.report.title} Files`
        }
        return "Mailer Files"
      },             
      ...mapGetters(['accountId', 'accountName', 'permissions']),
    },
    watch: {
      accountId () {
        this.loadFiles()
      },
    },
    created () {
      this.reportId = (this.$route.params.report_id)
      this.loadFiles()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadFiles: function () {
        this.loadingMessage = 'Loading Files'
        this.items = []
        this.loading = true
        DmsService.getScheduledReport (this.reportId) 
          .then(response => {
            this.report = response.data          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })


        DmsService.getScheduledReportFiles (this.reportId) 
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      goHome: function () {
        this.$router.push({ name: 'Home' })
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this Report File?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loadingMessage = 'Deleting Report File'
          this.loading = true
          DmsService.deleteScheduledReportFile(this.reportId, item.uuid)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
   
    },
  }
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('span',{staticClass:"font-weight-light subtitle-1"},[_c('v-btn',{attrs:{"elevation":"1","color":"blue darken-1","dark":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-keyboard-backspace ")]),_vm._v(" Back ")],1)],1),_c('base-material-card',{staticStyle:{"background-color":"#FAFAFA"},attrs:{"icon":"mdi-file-document-outline","color":"secondary","title":"Mailer Files"}},[_c('v-row',[_c('v-col')],1),_c('v-col',[_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.items,"items-per-page":50,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(value, true))+" ")]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(item.start_date, false))+" - "+_vm._s(_vm.formatIsoDate(item.end_date, false))+" ")]}},{key:"item.number_rows",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value, true))+" ")]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.report_url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.filename)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Report File")])])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.showErrorDialog = false}},model:{value:(_vm.showErrorDialog),callback:function ($$v) {_vm.showErrorDialog=$$v},expression:"showErrorDialog"}},[_c('error-message',{attrs:{"error":_vm.error},on:{"closeErrorDialog":_vm.closeErrorDialog}})],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"#FF5E40","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.noPermissions),callback:function ($$v) {_vm.noPermissions=$$v},expression:"noPermissions"}},[_c('v-card',{attrs:{"color":"warning","dark":""}},[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" You do not have permissions to access this feature. Contact Dealer Insights support for further assistance. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.goHome()}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }